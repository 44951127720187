import React from "react";
import PropTypes from "prop-types";
import { Dialog, Typography, Grid } from "@material-ui/core";
import './about.scss'

const About = ({ open, handleClose }) => (
  <Dialog onClose={handleClose} open={open} >
    <div style={{ overflow: "hidden", width: "100%", height: "100%" }} className="about-body">
      <Grid
        container
        spacing={2}
        direction="column"
        alignItems="center"
        style={{
          fontFamily: "AmericanSans-Regular,Roboto,sans-serif",
          width: "200px",
          height: "150px",
          display: "grid",
        }}
      >
        <Grid item md={12} xs={12} style={{ textAlign: "center" }}>
          <Typography
            color="primary"
            style={{
              fontFamily: "AmericanSans-Medium,Roboto,sans-serif",
              color: "#00467f",
              fontSize: "26px",
            }}
          >
            MyTime
          </Typography>
          <Typography
            color="secondary"
            style={{
              fontFamily: "AmericanSans-Regular,Roboto,sans-serif",
              color: "#627a89",
              fontSize: "18px",
            }}
          >
            Version 2.6
          </Typography>
        </Grid>
      </Grid>
    </div>
  </Dialog>
);
About.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default About;
